import httpUtil from "@/utils/httpUtil";

/** 获取客户标签 */
export function fetchClient(params,type) {
	return httpUtil.post("/api/crmPc/customer/getTree", params, type)
}


/** 保存客户标签 */
export function addLableList(params,type) {
	return httpUtil.post("/api/crmPc/customer/addLableList", params, type)
}


/** 获取客户列表数据 */
export function getCustomerPC(params,type) {
	return httpUtil.post("/api/crmPc/customer/getCustomerPC", params, type)
}

/** 获取成员列表数据 */
export function getCompanyStaffList(params,type) {
	return httpUtil.post("/api/crmPc/customer/getCompanyStaffList", params, type)
}

/** 转移客户 */
export function moveCustomer(params,type) {
	return httpUtil.post("/api/crmPc/customer/moveCustomer", params, type)
}


/** 抢为独占客户 */
export function updBatchCustomerState(params,type) {
	return httpUtil.post("/api/crmPc/customer/updBatchCustomerState", params, type)
}

/** 更新客户信息 */
export function updCustomer(params,type) {
	return httpUtil.post("/api/crmPc/customer/updCustomer", params, type)
}

/** 新增客户信息 */
export function manualAdd(params,type) {
	return httpUtil.post("/api/crmPc/customer/manualAdd", params, type)
}

/** 获取客户信息 */
export function getCustomerInfo(params,type) {
	return httpUtil.post("/api/crmPc/customer/getCustomerInfo", params, type)
}


/** 获取客户信息 */
export function getFollowList(params,type) {
	return httpUtil.post("/api/crmPc/customer/getFollowList", params, type)
}


/** 添加跟进记录 */
export function addFollow(params,type) {
	return httpUtil.post("/api/crmPc/customer/addFollow", params, type)
}

/** 添加成交记录 */
export function inputDealMoney(params,type) {
	return httpUtil.post("/api/crmPc/customer/inputDealMoney", params, type)
}


/** 获取成交记录 */
export function getInputDealListt(params,type) {
	return httpUtil.post("/api/crmPc/customer/getInputDealList", params, type)
}


/** 设置广告图 */
export function insertCompanyPhoto(params,type) {
	return httpUtil.post("/api/crmPc/companyPhoto/insertCompanyPhoto", params, type)
}



/** 获取广告图 */
export function selectCompanyPhoto(params,type) {
	return httpUtil.post("/api/crmPc/companyPhoto/selectCompanyPhoto", params, type)
}


/** 获取售后人员列表 */
export function queryCustomerSale(params,type) {
	return httpUtil.post("/api/crmPc/customer/queryCustomerSale", params, type)
}

/** 设置售后人员 */
export function setCustomerSale(params,type) {
	return httpUtil.post("/api/crmPc/customer/setCustomerSale", params, type)
}

/** 保存售后记录 */
export function saveSaleConsultant(params,type) {
	return httpUtil.post("/api/crmPc/customer/saveSaleConsultant", params, type)
}

/** 查询售后记录 */
export function querySaleConsultant(params,type) {
	return httpUtil.post("/api/crmPc/customer/querySaleConsultant", params, type)
}



